<script>
export default {
  name: 'Filterlist'
}
</script>

<template>
  <div class="filter clearfix">
    <div class="center">
      <slot
        v-if="!$media.mobile"
        name="button"
      />
      <slot
        v-if="!$media.mobile"
        name="other"
      />
      <slot
        v-if="!$media.mobile"
        name="calendar"
      />
      <slot
        v-if="!$media.mobile"
        name="filterTab"
      />
      <div
        v-if="$slots.tag || $slots.order || $slots.type || $slots.search"
        class="filter-options"
      >
        <slot name="order" />
        <slot name="tag" />
        <slot name="search" />
        <slot name="type" />
      </div>
      <slot
        v-if="$media.mobile"
        name="other"
      />
      <!-- <div class="filter-button" v-if="$media.mobile && $slots.button">
        <slot name="button"></slot>
      </div> -->
      <slot
        v-if="$media.mobile"
        name="filterTab"
      />
    </div>
    <div
      v-if="$media.mobile && $slots.button"
      class="filter-button"
      :class="{'space-margin-top': $media.mobile && ($slots.tag || $slots.order || $slots.type || $slots.search)}"
    >
      <slot name="button" />
    </div>
    <slot
      v-if="$media.mobile"
      name="calendar"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/filterlist.css"></style>
